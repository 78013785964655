.foods-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.foods-container .title {
  max-width: 340px;
}
.foods-container .title h1 {
  font-weight: 500;
  margin-bottom: 10px;
}
.foods-container .secondary-title {
  font-weight: 500;
  margin-bottom: 10px;
  padding: 0 20px;
  text-align: center;
  font-size: 1rem;
  color: var(--dark-title);
}
.foods-container .choose-all-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 10px;
}

.foods-container .choose-all-buttons button {
  border-radius: 55px;
  height: 30px;
  padding: 20px 40px;
  background-color: var(--light-blue);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}
.foods-container .choose-all-buttons .remove-all {
  background-color: #ffffff;
  color: var(--orange-btn);
  border: 1px solid var(--orange-btn);
}

.foods-container .choose-all-buttons .select-all {
  background-color: #ffffff;
  color: #50b848;
  border: 1px solid #50b848;
}

/* top container for wanted foods  */
.foods-container .food-types {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: flex-start; */
  width: 85vw;
  max-width: 500px;
  margin: 50px 0 20px 0;
  cursor: pointer;
  padding: 0 15px;
}
.foods-container .food-types .food-wrapper {
  background-color: var(--light-blue);
  padding: 4px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 8px 5px;
  color: #ffffff;
}
/* bottom container for unwanted foods */
.foods-container .unwanted-food-types {
  margin: 20px 0;
}
.foods-container .unwanted-food-types .unwanted-food-wrapper {
  background-color: #fbfbfb;
  color: var(--dark-title);
}
.foods-container .dotted-line {
  width: 80%;
  border-bottom: 3px dotted grey;
  max-width: 350px;
}
.foods-container .error {
  margin-bottom: 20px;
}
.foods-container .button-wrapper {
  margin: 80px 0 0 0;
}

@media (min-width: 750px) {
  .foods-container .secondary-title,
  .foods-container .choose-all-buttons button {
    font-size: 1.2rem;
  }
  .foods-container .food-types {
    max-width: 700px;
  }
  .foods-container .dotted-line {
    max-width: 500px;
  }
}
@media (max-width: 400px) {
  .foods-container .choose-all-buttons button {
    padding: 15px 30px;
    font-size: 0.9rem;
  }
}
@media (max-width: 330px) {
  .foods-container .choose-all-buttons button {
    font-size: 0.7rem;
  }
}
