.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 76.5vh;
}

.welcome-container .title {
  width: 100%;
}
.welcome-container .buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.welcome-container .main-box {
  width: 100%; /* Set the width to 100% to ensure the entire image is visible */
  height: 100%;
  min-height: 250px;
  padding: 0 20px;
  max-width: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/boxes/frame.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; /* Use 'contain' to fit the entire image within the container */
}
.welcome-container .main-box img {
  width: 200px;
}
.welcome-container .need-help {
  font-size: 1.3rem;
  font-weight: 400;
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
.welcome-container .buttons .orange-btn,
.welcome-container .buttons .color-btn {
  padding: 20px 47px;
  border-radius: 55px;
  min-width: 255px;
  background-color: var(--orange-btn);
  color: white;
  font-size: 1rem;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 500;
}
.welcome-container .buttons .color-btn {
  background-image: url("../../images/Untitled-3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 50px;
}

@media (min-width: 750px) {
  .welcome-container .buttons {
    flex-direction: row;
    align-items: baseline;
    gap: 50px;
  }
  .welcome-container .buttons .orange-btn,
  .welcome-container .buttons .color-btn {
    padding: 15px 47px;
  }
}

@media (max-width: 400px) {
  .welcome-container .main-box img {
    width: 150px;
  }
}
