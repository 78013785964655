.general-container .space {
  margin-top: 40px;
}
.general-container.pick-user {
  min-height: 72vh;
}
.pick-user .main-image {
  width: 100%;
  height: 100%;
  min-height: 250px;
  padding: 0 20px;
  max-width: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/boxes/frame.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
}
.pick-user .main-image img {
  width: 200px;
}
.no-users {
  color: #3aa7d5;
  font-size: 1.5rem;
  padding: 0 20px;
}

.add-to-profile {
  font-size: 1.2rem;
}
.existing-users-wrapper {
  width: 80vw;
  max-width: 450px;
  padding: 50px 20px 0 20px;
  display: flex;
  flex-wrap: wrap; /* Allow users to wrap to a new line */
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.existing-users-wrapper .user {
  max-width: calc(
    33.33% - 20px
  ); /* 33.33% width for each user with a 20px gap between them */
  text-align: center; /* Center the user div within its space */
}

.existing-users-wrapper .user .user-img {
  width: 70%;
  /* max-width: 120px;
  max-height: 98px; */
}
.existing-users-wrapper .user .user-img img {
  width: 120px;
  height: 120px;
  background-color: #ffffff;

  border-radius: 50%;
  border: 4px solid var(--baby-blue, #a2d2e2);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.existing-users-wrapper .user .user-name {
  font-size: 0.9rem;
  color: var(--dark-title, #3c4964);
  margin-top: 10px;
}
.pick-user .error {
  color: red;
  font-size: 1.5rem;
  padding: 0 20px;
  margin: 0;
}
.pick-user .button-wrapper {
  margin: 80px 0 80px 0;
  gap: 10px;
}

@media (max-width: 750px) {
  .general-container.pick-user {
    min-height: 87vh;
  }
}

@media (max-width: 550px) {
  .existing-users-wrapper .user .user-img img {
    width: 90px;
    height: 90px;
  }
}
@media (max-width: 400px) {
  .existing-users-wrapper .user .user-img img {
    width: 60px;
    height: 60px;
  }
  /* .no-users {
    font-size: 1rem;
  }
  .add-to-profile {
    font-size: 0.8rem;
  } */
}
