.contact-container {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 400px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 100;
  overflow: hidden;
  padding: 0 10px;
}
.contact-container .contact-box {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-container .contact-box header {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  width: 100%;
}
.contact-container .contact-box header .help {
  width: 18%;
  height: auto;
  margin-bottom: 10px;
}
.contact-container .contact-box header .logo {
  width: 54%;
  height: auto;
  margin-bottom: 10px;
}
.contact-container .contact-box p {
  width: 90%;
  font-weight: 500;
  text-align: center;
  margin-bottom: 8px;
}
.contact-container .contact-box a {
  color: black;
  text-decoration: none;
}
.contact-container .contact-box button {
  background-color: var(--orange-btn);
  color: white;
  width: 90%;
  font-size: 1rem;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  padding: 12px 28px;
  border-radius: 55px;
  margin-bottom: 20px;
}
.contact-container .contact-box button.scan-box-guide {
  background-color: #a2d2e2;
  margin: 20px auto;
}
@media (max-width: 350px) {
  .contact-container .contact-box button {
    font-size: 0.8rem;
  }
}
