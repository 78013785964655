@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,800;1,300;1,600&display=swap");

:root {
  --orange-btn: #f68222;
  --dark-title: #3c4964;
  --light-blue: #3aa7d5;
  --font-family: "Rubik", sans-serif;
}

* {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
}

.general-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 76.5vh;
  background: linear-gradient(
    180deg,
    rgba(154, 197, 77, 0) 36.21%,
    #50b848 100%
  );
  background-clip: content-box;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error {
  text-align: center;
  color: red;
  font-size: 1rem;
  font-weight: 400;
}
.main-image {
  /* width: 80vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.main-image img {
  width: clamp(100%, 30%, 100%);
}

.button-wrapper {
  padding: 20px 47px;
  border-radius: 55px;
  min-width: 225px;
  width: 75vw;
  max-width: 400px;
  background-color: var(--orange-btn);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 100px 0;
  gap: 20px;
  cursor: pointer;
  border: none;
}
.button-wrapper button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
}
@media (max-width: 751px) {
  .general-container {
    min-height: 88.9vh;
  }
}
@media (max-width: 450px) {
  .general-container {
    min-height: 90vh;
  }
}
@media (max-width: 350px) {
  .button-wrapper {
    padding: 15px 32px;
    gap: 10px;
  }

  .button-wrapper button {
    font-size: 1rem;
  }
}
@media (max-width: 300px) {
  .general-container {
    min-height: 95vh;
  }
}
