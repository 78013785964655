.main-content .header-container {
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-content .header-container .logo {
  display: flex;
  justify-content: flex-start;
  padding-left: 7%;
  align-items: center;
}
.main-content .header-container .logo img {
  width: clamp(150px, 50%, 220px);
}
.main-content .header-container .language {
  display: flex;
  justify-content: flex-end;
  padding-right: 7%;
  align-items: center;
  margin-top: 5px;
}
.desktop-divider {
  width: 80vw;
  border-bottom: 3px yellow dotted;
  margin: 20px auto 0 auto;
}
@media (max-width: 400px) {
  .main-content .header-container .language {
    padding-right: 0;
  }
  .main-content .header-container .logo {
    padding-left: 0;
  }
}
