/* .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
} */
.main-content {
  min-height: 80vh;
}
.popup-open {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  filter: brightness(25%);
  background-color: var(--dark-title);
  pointer-events: none;
}
