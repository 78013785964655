.disclaimer-container {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 400px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 100;
  overflow: hidden;
  padding: 0 30px;
}
.disclaimer-container .star {
  padding: 30px 0;
}
.disclaimer-container .star img {
  width: 120px;
  height: 120px;
}
.disclaimer-container .disclaimer h2 {
  font-size: 1.2rem;
}
.disclaimer-container .disclaimer p {
  font-size: 1rem;
}
.disclaimer-container .disclaimer p span {
  color: #d83a30;
}
.disclaimer-container .disclaimer p span:hover {
  text-decoration: underline;
}
.disclaimer-container .form-wrapper {
  margin-top: 50px;
  display: flex;
  gap: 10px;
}
.disclaimer-container .error {
  margin-top: 20px;
  font-size: 0.9rem;
}
.disclaimer-container .button-wrapper {
  background-color: #d83a30;
  width: 100%;
  margin: 20px auto 50px auto;
  padding: 15px 32px;
}
@media (max-width: 550px) {
  .disclaimer-container .star img {
    width: 90px;
    height: 90px;
  }
  /* .disclaimer-container .button-wrapper {
 
    justify-content: space-around;
    min-width: 220px;
  } */
}

@media (max-width: 370px) {
  .disclaimer-container .button-wrapper {
    padding: 12px 20px;
    min-width: 160px;
  }
}
