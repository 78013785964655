.allergies-container {
  background: linear-gradient(
    180deg,
    rgba(243, 111, 33, 0) 36.21%,
    #f36f21 100%
  );
}
.allergies-container .allergies-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 15px;
}

.allergies-container .allergies-wrapper .allergies-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 400px;
  width: 80vw;
  margin-bottom: 20px;
}

.allergies-container .allergies-wrapper .allergies-row .allergy {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.allergies-container
  .allergies-wrapper
  .allergies-row
  .allergy
  .allergy-circle {
  border-radius: 50%;
  border: 1px solid #d83a30;
  padding: 20px;
  background-color: #ffffff;
}

.allergies-container
  .allergies-wrapper
  .allergies-row
  .allergy
  .allergy-circle.chosen {
  background-color: #d83a30;
}

.allergies-container
  .allergies-wrapper
  .allergies-row
  .allergy
  .allergy-circle
  img {
  width: 40px;
  height: 40px;
}

.allergies-container .button-wrapper {
  margin: 80px 0 0 0;
}
/* .allergies-container .button-wrapper.disabled {
  background-color: #d3d3d3;
  color: #6a6a6a;
}
.allergies-container .button-wrapper.disabled button {
  color: #6a6a6a;
} */

@media (max-width: 400px) {
  .allergies-container
    .allergies-wrapper
    .allergies-row
    .allergy
    .allergy-circle {
    padding: 8px;
  }
  .allergies-container
    .allergies-wrapper
    .allergies-row
    .allergy
    .allergy-name {
    font-size: 0.8rem;
  }
}

@media (max-width: 300px) {
  .allergies-container
    .allergies-wrapper
    .allergies-row
    .allergy
    .allergy-name {
    font-size: 0.6rem;
  }
}
