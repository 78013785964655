.back-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  max-width: 300px;
  margin: 20px 0 50px 0;
  cursor: pointer;
}
.back-btn-wrapper .text {
  font-size: 1rem;
  font-weight: 500;
}
