.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 72vh;
  /* opacity: 0.5; */
  background: linear-gradient(
    180deg,
    rgba(154, 197, 77, 0) 36.21%,
    #50b848 100%
  );
  background-clip: content-box;
}
/* .verification-form-wrapper {
  width: 100vw;
} */
.verification-form-wrapper form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.verification-form-wrapper form .code-verification input {
  border: none;
  border-bottom: 2px solid var(--dark-title);
  outline: none;
  padding-inline-start: 40%;
  width: 15vw;
  font-size: clamp(1rem, 0.5493rem + 0.7895vw, 1.8rem);
  max-width: 70px;
}
.verification-container .user-email,
.verification-container .wrong-email {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  color: var(--dark-title);
}
/* .verification-container .user-email p {
} */
.verification-container .wrong-email {
  text-decoration: underline;
  margin: 20px 0 20px 0;
  cursor: pointer;
}

.verification-container .main-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-image img.mask {
  width: min(20%, 300px);
  min-width: 250px;
}

.verification-container .submit {
  width: 70%;
  margin: 150px auto 30px auto;
  display: flex;
  justify-content: space-between;
  padding: 15px 47px;
  border-radius: 55px;
  min-width: 230px;
  max-width: 270px;
  background-color: var(--orange-btn);
  color: white;
  font-size: clamp(0.875rem, 0.7rem + 0.5263vw, 1.25rem);
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.verification-container .submit button {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 18px;
}
@media (max-width: 750px) {
  .verification-container {
    min-height: 87vh;
  }
}
