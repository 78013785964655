/* same titles  */
.title p,
.title .box-size {
  margin: 30px 0 0 0;
  text-align: center;
  font-size: 1.5rem;
  color: var(--dark-title);
}
.title h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--dark-title);
  width: 100%;
}
.title p.box-size {
  margin: 0 0 30px 0;
  font-size: 1.7rem;
}
