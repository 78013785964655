/* desktop */
.footer {
  position: relative;
  bottom: 0;
  background-color: var(--dark-title);
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* mobile */

.footer.mobile {
  height: 60px;
}
.footer.mobile .main.mobile {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}
.footer.mobile .main.mobile .blog img {
  width: 35px;
  height: auto;
}
/* desktop */
.footer .credit {
  background-color: var(--dark-title);
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 0 25px;
  cursor: pointer;
  width: 20%;
}
.footer .main {
  background-color: var(--dark-title);
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-grow: 1;
  padding: 0 25px;
  width: 80%;
}

.footer .main .videos,
.footer .main .clean,
.footer .main .blog,
.footer .main .privacy,
.footer .main .contact-us,
.footer .main .about-us,
.footer .main .help {
  display: flex;
  background-color: var(--dark-title);
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
.footer .main .videos img,
.footer .main .clean img,
.footer .main .blog img {
  background-color: var(--dark-title);
  width: 20px;
  height: auto;
}
.footer .main .videos p,
.footer .main .clean p,
.footer .main .blog p,
.footer .credit p,
.footer .main .privacy p,
.footer .main .contact-us p,
.footer .main .about-us p,
.footer .main .help p {
  background-color: var(--dark-title);
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0;
}
.footer .main .footer-icon {
  font-size: 20px;
}
@media (min-width: 751px) and (max-width: 980px) {
  .footer .main {
    gap: 10px;
  }
  /* .footer .main .videos img,
  .footer .main .clean img,
  .footer .main .blog img {
    width: 10px;
    height: auto;
  } */
  .footer .main .footer-icon {
    font-size: 10px;
  }
  .footer .main .videos p,
  .footer .main .clean p,
  .footer .main .blog p,
  .footer .credit p,
  .footer .main .privacy p,
  .footer .main .contact-us p,
  .footer .main .about-us p,
  .footer .main .help p {
    font-size: 0.6rem;
  }
}
