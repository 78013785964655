.diets-container .choose-diet {
  padding: 0 20px;
}

.diets-container .choose-diet .diet-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin: 10px;
}

.diets-container .choose-diet .diet-row .diet-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 60px;
  max-width: 100px;
}

.diets-container .choose-diet .diet-row .diet-type .diet-image-circle {
  border-radius: 50%;
  min-height: 100px;
  border: 1px solid var(--light-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  text-align: center; /* Center text horizontally */
  white-space: normal; /* Allow text to wrap */
}

.diets-container .choose-diet .diet-row .diet-type .diet-image-circle.chosen {
  border: 4px solid var(--light-blue);
}

.diets-container .choose-diet .diet-row .diet-type .diet-image-circle img {
  max-width: 70%;
  min-width: 30px;
  min-height: 30px;
  padding: 5px;
}

.diets-container .choose-diet .diet-row .diet-type .diet-name {
  font-size: 0.8rem;
  font-weight: 500;
  color: #6a6a6a;
  text-align: center;
}

.diets-container .button-wrapper {
  margin: 80px 0;
}

/* @media (min-width: 750px) {
  .diets-container .button-wrapper {
    margin: 80px 0;
  }
} */

@media (min-width: 490px) {
  .diets-container .choose-diet .diet-row .diet-type .diet-name {
    text-align: left;
  }
}

@media (max-width: 489px) {
  /* .diets-container .choose-diet .diet-row .diet-type .diet-image-circle {
    min-height: 80px;
  } */

  .diets-container .choose-diet .diet-row .diet-type .diet-image-circle img {
    max-width: 70%;
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
  }
}
