.register-container,
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 89vh;
  background-image: url("../../images/mobile/register.jpg");
  background-position: center;
  background-size: cover;
}

.register-form-wrapper form,
.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 60px;
}
.register-form-wrapper form .form-control,
.login-container form .form-control {
  margin-bottom: 0.5rem;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-form-wrapper form .form-control input,
.login-container form .form-control input {
  border-radius: 4px;
  width: 75vw;
  max-width: 400px;
  background: #fff;
  color: var(--dark-title);
  height: 50px;
  border: none;
  padding-inline-start: 20px;
  border-bottom: 2px solid var(--dark-title);
}

.register-form-wrapper form .approve-marketing {
  display: flex;
  align-items: center;

  margin-top: 20px;
  width: 75vw;
  max-width: 400px;
  justify-content: flex-start;
}
.register-form-wrapper form input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.register-form-wrapper form .approve-marketing span {
  text-decoration: underline;
  color: #4798d2;
  cursor: pointer;
  font-size: 0.8rem;
}

.register-form-wrapper form .form-control input::placeholder,
.login-container form .form-control input::placeholder {
  color: var(--dark-title);
}

.register-form-wrapper form .form-control input:focus,
.login-container form .form-control input:focus {
  outline: none;
}
.login-container form .forgot-password {
  font-size: 0.8rem;
  color: #007cc4;
  text-decoration: underline;
  cursor: pointer;
}
.login-container form .forgot-password:hover {
  color: red;
}
.register-form-wrapper form .form-submission,
.login-container form .form-submission {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 15px 47px;
  border-radius: 55px;
  min-width: 230px;
  background-color: var(--orange-btn);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.register-form-wrapper form .form-submission {
  border: none;
}
.register-form-wrapper form .form-submission input,
.login-container form .form-submission input {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 18px;
}
@media (min-width: 749px) {
  .register-container,
  .login-container {
    background-image: url(../../images/desktop/background-register-view-desktop-jpg.jpg);
    background-position: center;
    background-size: cover;
    min-height: 76.5vh;
  }
}
