.verify-email h1 {
  margin: 100px 0 50px 0;
}
.verify-email-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.verify-email-wrapper input {
  width: 75vw;
  max-width: 400px;
  height: 50px;
  border-radius: 4px;
  border: none;
  padding-inline-start: 20px;
  border-bottom: 2px solid var(--dark-title);
  background: #fff;
  color: var(--dark-title);
}
.verify-email-wrapper input::placeholder {
  color: var(--dark-title);
  font-size: 1rem;
}
.verify-email-wrapper input:focus {
  outline: none;
}
.verify-email-wrapper .error {
  margin-top: 0;
}
.verify-email-wrapper .buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.verify-email-wrapper .buttons button {
  display: flex;
  justify-content: center;
  padding: 15px 47px;
  border-radius: 55px;
  min-width: 180px;
  background-color: var(--orange-btn);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

@media (max-width: 450px) {
  .verify-email-wrapper .buttons {
    flex-direction: column;
  }
  .verify-email-wrapper .buttons button {
    width: 80vw;
  }
}
