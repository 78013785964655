.general-container.create-profile {
  min-height: 70vh;
}

.general-container.create-profile .avatar-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/avatar/avatar-frame.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 280px;
  height: 180px;
}

.general-container.create-profile .avatar-image img.yoopi-avatar {
  width: 130px;
}
.general-container.create-profile .avatar-image img.camera-avatar {
  width: 170px;
  height: 173px;
  border-radius: 50%;
  margin-right: 6px;
  margin-bottom: 2px;
}

.change-avatar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}
.create-profile-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 50px;
}
.create-profile-form form .form-control {
  margin-bottom: 10px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-profile-form form .form-control input {
  border-radius: 4px;
  width: 75vw;
  max-width: 400px;
  background: #fff;
  color: var(--dark-title);
  height: 50px;
  border: none;
  border-bottom: 2px solid var(--dark-title);
}
.create-profile-form form .form-control input:focus {
  outline: none;
}
.create-profile-form form .button-wrapper {
  border: none;
}
.create-profile-form form .button-wrapper .create {
  font-size: 1rem;
  font-weight: 500;
}
