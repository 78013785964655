.hubbies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 88.9vh;
  background-image: url("../../images/mobile/hubbies.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.hubbies-container .select-wrapper {
  margin-top: 50px;
}
.hubbies-container .select-wrapper .css-zqnv80-control:hover {
  border-color: transparent;
  box-shadow: none;
  border-bottom: 2px solid #3c4964;
}

.hubbies-container .display-selected,
.hubbies-container .popular-choices .popular-choice-wrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 85vw;
  max-width: 500px;
  cursor: pointer;
  padding: 0 15px;
}
.hubbies-container .popular-choices .popular-choice-wrapper {
  padding-left: 12%;
}
.hubbies-container .display-selected .selected-option-wrapper,
.hubbies-container .popular-choices .popular-choice-wrapper .choice {
  background-color: var(--light-blue);
  padding: 4px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 8px 5px;
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.hubbies-container .popular-choices .popular-choice-wrapper .choice {
  background-color: #ffffff;
  color: var(--dark-title);
  border: 1px solid var(--dark-title);
}

.hubbies-container .popular-choices {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.hubbies-container .popular-choices h1 {
  font-size: clamp(0.8rem, 1.4819rem + 0.4605vw, 2rem);
}
.hubbies-container .error {
  margin-top: 50px;
  font-weight: 500;
}
.hubbies-container .button-wrapper {
  margin: 80px 0 0 0;
}
/*  on 650px change main container background from image to linear-gradient  */
@media (min-width: 750px) {
  .hubbies-container {
    min-height: 76.5vh;
    background-image: none;
    background: linear-gradient(
      180deg,
      rgba(154, 197, 77, 0) 36.21%,
      #50b848 130%
    );
    background-clip: content-box;
  }

  .hubbies-container .button-wrapper {
    margin: 50px 0 20px 0;
  }
}

@media (max-width: 450px) {
  .education-container {
    min-height: 87vh;
  }
}
