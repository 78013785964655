.general-container.change-avatar-container {
  min-height: 100vh;
}

.change-avatar-container .title {
  margin-top: 0px;
}

.change-avatar-container .chosen-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.change-avatar-container .chosen-avatar .avatar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%; /* Adjust to your preference */
  border: 4px solid var(--light-blue);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.change-avatar-container .chosen-avatar .avatar-wrapper img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  padding: 15px;
}

/* selected avatar is camera captured   */
.change-avatar-container .chosen-avatar .avatar-wrapper img.captured {
  width: 230px;
  height: 230px;
}

/* both buttons */
.change-avatar-container .take-photo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  color: var(--light-blue);
  border: 1px solid var(--light-blue);
  width: 80%;
  width: 200px;
  margin-top: 20px;
  height: 45px;
  border-radius: 55px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.change-avatar-container .take-photo-wrapper .take-photo-para {
  font-size: 1.1rem;
  font-weight: 500;
}
.camera-container {
  min-width: 300px;
  min-width: 300px;
  width: 400px !important;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center; /* centers the webcam and button horizontally */
}
.camera-container video {
  width: 50%;
  border-radius: 50%;
  border: 4px solid var(--light-blue);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.change-avatar-container .list-of-avatars {
  width: 100%;
  max-width: 450px;
  padding: 50px 40px;
  display: flex;
  flex-wrap: wrap; /* Allow users to wrap to a new line */
  justify-content: space-between; /* Distribute items evenly along the line */
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.change-avatar-container .list-of-avatars .avatar {
  flex-basis: calc(
    33.33% - 20px
  ); /* 33.33% width for each avatar with a 20px gap between them */
  box-sizing: border-box; /* To ensure padding and border are included in the item's total width */
  text-align: center; /* Center the avatar div within its space */
}
.change-avatar-container .list-of-avatars .avatar .avatar-image {
  max-width: 120px;
}
.change-avatar-container .list-of-avatars .avatar .avatar-image img {
  width: 80px;
  border-radius: 89px;
  border: 4px solid #a2d2e2;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.change-avatar-container .list-of-avatars .avatar .avatar-image img.active {
  background-color: #a2d2e2;
}
.change-avatar-container .list-of-avatars .avatar .avatar-name {
  font-size: 1rem;
  color: var(--dark-title, #3c4964);
  margin-top: 10px;
}
.change-avatar-container .button-wrapper {
  margin: 0 0 100px 0;
  max-width: 370px;
}
@media (min-width: 750px) {
  .change-avatar-container .chosen-avatar .avatar-wrapper {
    width: 200px;
    height: 200px;
  }

  .change-avatar-container .chosen-avatar .avatar-wrapper img {
    width: 210px;
    height: 210px;
  }

  /* selected avatar is camera captured   */
  .change-avatar-container .chosen-avatar .avatar-wrapper img.captured {
    width: 300px;
  }

  .change-avatar-container .list-of-avatars {
    justify-content: space-around;
    padding: 50px;
    max-width: 1200px;
  }

  .change-avatar-container .list-of-avatars .avatar {
    flex-basis: auto; /* Remove fixed width */
  }
  .change-avatar-container .list-of-avatars .avatar .avatar-image {
    max-width: 90px;
  }
}
