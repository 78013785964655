.education-container {
}

.education-container .selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.education-container .selection .selection-row {
  display: flex;
  justify-content: space-evenly;
  max-width: 500px;
  padding: 0 20px;
}
.education-container .selection .selection-row .choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  cursor: pointer;
}

.education-container .selection .selection-row .choice .choice-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  border: 1px solid var(--light-blue);
  background: #fff;
  padding: 12px 7px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: clamp(80px, 100%, 86px);
  /* height: clamp(90px, 100%, 120px); */
  margin-bottom: 10px;
}
.education-container .selection .selection-row .choice .choice-img.selected {
  background: var(--light-blue);
  border: none;
}

.education-container .selection .selection-row .choice .choice-img img {
  width: clamp(40px, 100%, 50px);
  margin-bottom: 10px;
}

.education-container .selection .selection-row .choice .choice-title {
}

.education-container .educational-data {
  margin-top: 50px;
}

.education-container .educational-data .hours {
  margin: 10px 0;
}

.education-container .educational-data select {
  border-radius: 4px;
  border-bottom: 2px solid var(--dark-title);
  background: #fff;
  padding: 10px 20px;
  width: 70vw;
  max-width: 288px;
  min-width: 215px;
}
.error {
  margin-top: 100px;
}
.education-container .button-wrapper.disabled,
.education-container .button-wrapper.disabled .next-button {
  background-color: #d3d3d3;
  color: #6a6a6a;
}

@media (min-width: 750px) {
  /* .education-container .selection .selection-row {
    width: 100vw;
    max-width: 500px;
  } */
  .education-container .educational-data .hours {
    margin: 0;
  }

  .education-container .educational-data {
    gap: 50px;
  }
}
