.clean-box-container {
  top: 42%;
}
.clean-box-container .stars {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 30px 0;
}
.clean-box-container .stars img {
  width: 60%;
  margin: 0 auto;
}
.clean-box-container .clean-box {
}

.clean-box-container .clean-box h2 {
  font-size: 1.3rem;
  text-align: center;
  font-weight: 600;
}

.clean-box-container .clean-box p {
  font-size: 1rem;
}

.clean-box-container .clean-box p span {
  font-size: 1rem;
  display: block;
  font-weight: 500;
}
.clean-box-container .clean-box ul {
  margin-top: 20px;
}
.clean-box-container .clean-box ul li {
  margin-top: 20px;
}
.clean-box-container .button-wrapper {
  margin: 30px auto 20px auto;
}
