.welcome-container.kids-zone-container {
  justify-content: flex-start;
  padding: 0;
}
.kids-zone-container .kids-zone-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.kids-zone-container .kids-zone-main .kids-zone-img {
  width: 100%;
}
.kids-zone-container .kids-zone-main .kids-zone-img img {
  width: 100%;
  margin-bottom: 50px;
}

/* mobile  */
.kids-zone-container .kids-zone-main .games-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.kids-zone-container .kids-zone-main .games-container-mobile .category-games {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  border-bottom: 1px dotted #909094;
  width: 100%;
}
.kids-zone-container
  .kids-zone-main
  .games-container-mobile
  .category-games
  h2 {
  font-size: 1.8rem;
  text-align: left;
  padding-left: 10%;
  width: 100%;
  margin-bottom: 20px;
  font-weight: 600;
}
.kids-zone-container
  .kids-zone-main
  .games-container-mobile
  .category-games
  .game-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 10px 20px 10%;
}
.kids-zone-container
  .kids-zone-main
  .games-container-mobile
  .category-games
  .game-mobile
  .game-image-mobile {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  cursor: pointer;
}
.kids-zone-container
  .kids-zone-main
  .games-container-mobile
  .category-games
  .game-mobile
  .game-content-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px 0 10px 0;
  border-bottom: 1px dotted #909094;
  width: 250px;
}
.kids-zone-container
  .kids-zone-main
  .games-container-mobile
  .category-games
  .game-mobile
  .game-content-mobile
  h4 {
  cursor: pointer;
  color: #454545;
  font-size: 1.5rem;
}
.kids-zone-container
  .kids-zone-main
  .games-container-mobile
  .category-games
  .game-mobile
  .game-content-mobile
  p {
  font-size: 1.2rem;
  color: #909094;
}
/* desktop  */

.kids-zone-container .kids-zone-main .games-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0 20px;
  margin: 30px 0;
}
.kids-zone-container .kids-zone-main .games-container .category-games-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  border-bottom: 1px dotted #909094;

  width: 100%;
}
.kids-zone-container
  .kids-zone-main
  .games-container
  .category-games-desktop
  H2 {
  margin-bottom: 20px;
}
.kids-zone-container
  .kids-zone-main
  .games-container
  .category-games-desktop
  .game-row {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.kids-zone-container
  .kids-zone-main
  .games-container
  .category-games-desktop
  .game-row
  .game {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kids-zone-container
  .kids-zone-main
  .games-container
  .category-games-desktop
  .game-row
  .game
  .game-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  cursor: pointer;
}
.kids-zone-container
  .kids-zone-main
  .games-container
  .category-games-desktop
  .game-row
  .game
  .game-content
  h4 {
  font-size: 1rem;
  color: #454545;
  cursor: pointer;
}
.kids-zone-container
  .kids-zone-main
  .games-container
  .category-games-desktop
  .game-row
  .game
  .game-content
  p {
  font-size: 0.8rem;
  color: #909094;
}

.kids-zone-container .kids-zone-main .back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.kids-zone-container .kids-zone-main .back-btn button {
  padding: 20px 47px;
  border-radius: 55px;
  min-width: 255px;
  background-color: var(--orange-btn);
  color: white;
  font-size: 1rem;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 500;
}

@media (max-width: 500px) {
  .kids-zone-container
    .kids-zone-main
    .games-container-mobile
    .category-games
    .game-mobile {
    gap: 10px;
    padding-left: 5%;
  }
  .kids-zone-container
    .kids-zone-main
    .games-container-mobile
    .category-games
    .game-mobile
    .game-image-mobile {
    width: 80px;
    height: 80px;
  }

  .kids-zone-container
    .kids-zone-main
    .games-container-mobile
    .category-games
    h2 {
    font-size: 1.6rem;
    padding-left: 5%;
  }
  .kids-zone-container
    .kids-zone-main
    .games-container-mobile
    .category-games
    .game-mobile
    .game-content-mobile
    h4 {
    font-size: 1.3rem;
  }
  .kids-zone-container
    .kids-zone-main
    .games-container-mobile
    .category-games
    .game-mobile
    .game-content-mobile
    p {
    font-size: 0.9rem;
  }
  .kids-zone-container .kids-zone-main .back-btn button {
    padding: 13px 30px;
    min-width: 200px;
  }
}
@media (max-width: 340px) {
  .kids-zone-container
    .kids-zone-main
    .games-container-mobile
    .category-games
    .game-mobile
    .game-content-mobile {
    padding-bottom: 5px;
  }
  .kids-zone-container
    .kids-zone-main
    .games-container-mobile
    .category-games
    .game-mobile
    .game-image-mobile {
    width: 70px;
    height: 70px;
  }
}
