.check-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  min-height: 76.5vh;
  margin: 0;
}

.check-account-container .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
/* .title p {
  margin: 50px 0 0 0;
  padding: 0 20px;
  text-align: center;
  font-size: clamp(1rem, 0.5493rem + 0.7895vw, 1.4375rem);
  color: var(--dark-title);
} */
.check-account-container .title h1 {
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;

  color: var(--dark-title);
}

.check-account-container .main-image {
  width: 100%;
  min-height: 250px;
  background-image: url(../../images/mobile/background-second-screen-mobilenew.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-account-container .main-image .main-image-frame {
  width: 100%; /* Set the width to 100% to ensure the entire image is visible */
  height: 100%;
  min-height: 250px;
  padding: 0 20px;
  max-width: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/mobile/back-frame-mobile.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; /* Use 'contain' to fit the entire image within the container */
}
.check-account-container .main-image .main-image-frame img {
  width: 200px;
}
.check-account-container .buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0 50px 0;
}
.check-account-container .buttons .new-family,
.check-account-container .buttons .existing-user {
  display: flex;
  min-width: 240px;
  max-width: 265px;
  padding: 20px 47px;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  background: var(--orange-btn);
  font-weight: 500;
  font-size: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.check-account-container .buttons div button {
  background-color: transparent;
  border: none;
  color: #fff;
}
.check-account-container .buttons .existing-user {
  border: 1px solid var(--orange-btn);
  background: #fff;
}
.check-account-container .buttons .existing-user button {
  color: var(--orange-btn);
}
@media (min-width: 750px) {
  .check-account-container .main-image {
    background-image: url(../../images/desktop/background-second-view-desktop.png);
  }

  .check-account-container .buttons {
    flex-direction: row;
    align-items: baseline;
    gap: 50px;
  }

  .check-account-container .buttons .new-family,
  .check-account-container .buttons .existing-user {
    padding: 15px 47px;
  }
}
@media (max-width: 400px) {
  .check-account-container .main-image .main-image-frame img {
    width: 150px;
  }
}
